<!--
 * @Description: 发票文件查看 invoiceFile
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-07 11:35:13
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="发票文件查看"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-image :src=invoiceFileImg />
    <van-button class="button"
                @click="handleClickButton">点击保存发票</van-button>
  </div>
</template>
<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      invoiceFileImg: ''
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // 获取开票图片
    this.invoiceFileImg = this.$route.query.pdfWord
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 下载发票
    handleClickButton () {
      if (window.android) {
        window.android.openBrowser(this.invoiceFileImg)
      } else {
      }
    },
    // 返回上一级
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .button {
    width: 93.6%;
    height: 50px;
    margin-left: 3.2%;
    margin-top: 10px;
    background: #19a9fc;
    color: white;
  }
}
</style>
